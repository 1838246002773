<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { BButton, BFormInput, BFormGroup, BForm } from 'bootstrap-vue'

export default {
  name: 'CreateContact',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    DatePicker,
  },
  data() {
    return {
      newContact: {
        full_name: null,
        zip_code: null,
        kid_name: null,
        birthday: null,
        email: null,
        phone: null,
      },
    }
  },
  methods: {
    handleFormSubmit: function () {
      let payload = {
        name: this.newContact.full_name,
        zip_code: this.newContact.zip_code,
        kid_name: this.newContact.kid_name,
        kid_dob: this.newContact.kid_dob,
        user_id: this.userData.id,
        email: this.newContact.email,
        phone: this.newContact.phone,
      }
      this.ContactService.create(payload)
        .then(success => {
          this.newContact = {
            full_name: null,
            zip_code: null,
            kid_name: null,
            birthday: null,
            email: null,
            phone: null,
          }
          // Navigate back to contacts list
        })
        .catch(err => {
          console.error('sub error', err)
        })
    },
  },
}
</script>

<template>
  <div class="create-contact">
    <h3 class="my-1">
      {{ $t('Create contact') }}
    </h3>
    <b-form>
      <b-form-group label-for="full_name" :label="$t('Parent name')">
        <b-form-input
          id="full_name"
          v-model="newContact.full_name"
          :placeholder="$t('Parent name')"
        />
      </b-form-group>

      <b-form-group label-for="email" :label="$t('Email')">
        <b-form-input id="email" v-model="newContact.email" :placeholder="$t('Email')" />
      </b-form-group>

      <b-form-group label-for="phone" :label="$t('Phone')">
        <b-form-input id="phone" v-model="newContact.phone" :placeholder="$t('Phone')" />
      </b-form-group>

      <b-form-group label-for="zip_code" :label="$t('ZIP code')">
        <b-form-input id="zip_code" v-model="newContact.zip_code" placeholder="10001" />
      </b-form-group>

      <b-form-group label-for="kid_name" :label="$t('Kid name')">
        <b-form-input id="kid_name" v-model="newContact.kid_name" :placeholder="$t('Kid name')" />
      </b-form-group>

      <b-form-group label-for="kid_dob" :label="$t('Kid date of birth')">
        <date-picker
          v-model="newContact.kid_dob"
          class="custom-style"
          format="DD/MM/YYYY"
          type="date"
          value-type="format"
          placeholder="DD/MM/YYYY"
        />
      </b-form-group>

      <b-form-group>
        <br />
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="handleFormSubmit"
        >
          {{ $t('Create contact') }}
        </b-button>
        <router-link to="/contacts" class="btn btn-toddl ml-2 float-right">
          {{ $t('Back') }}
        </router-link>
      </b-form-group>
    </b-form>
  </div>
</template>

<style scoped>
.create-contact {
  width: 60%;
  margin: 1em 20%;
  min-height: 65vh;
}
</style>
